.youngContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
 
 

  padding-bottom: 100px;
  /* min-height: 1200px; */

  /* height:30%; */
  /* padding: 20px 0; */
  /* padding-bottom: 90px; */
}

.wrappy {
  
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  /* min-height: 100%; */
}

.vignettes {
  /* display: block; */
  position:relative;
  height: 500px;
  width: 100%;
  /* margin-right: 50px;
  margin-left: 50px; */
  object-fit: cover;

  cursor: pointer;
}

.carte{
  position:absolute;
  top:0;
  right:0;
  background-color:REd;
  color:white;
}

.imgLayer {
  /* 
  
  bottom: 0;
  left: 0;
 
  right: 0; */

  /* 
   */

  position: absolute;
  top: 0;

  background: white;
  color: black;
  visibility: hidden;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.2s, visibility 0.2s;
  height: 100%;
  width: 100%;
}

.wrappy:hover .imgLayer {
  visibility: visible;
  opacity: 0.8;
  cursor: pointer;
}

.wrappy:active {
  visibility: visible;
  opacity: 0.8;
  cursor: pointer;
}

.opName {
  color:#6D8299;;
  
  transition: 0.2s;
  transform: translateY(1em);
  text-align: center;

  border-top: 1px solid;
  border-bottom: 1px solid;
  font-size: 40px;
  width: 100%;
  /* padding: 15px; */
  background: white;
}

.wrappy:hover .opName {
  transform: translateY(0);
}

/* .vignettes */

@media (max-width: 991px) {
  .vignettes {
    height: 400px;
    width: 300px;
  }
}

.lostInTranslation {
  max-width: 400px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.lego {
  /* padding: 10px 100px; */
  color: #6d8299;

  font-size: 20px;
  text-align: center;
  visibility: hidden;
  font-weight: 200;
}

.lego:hover {
  color: #eddcd2;
}

@media (max-width: 900px) {
  .lego {
    visibility: visible;
  }
}

.ghostdiv {
  padding-bottom: 100px;
  
  min-height:100%
}
